@import "tailwindcss/base";
@import "tailwindcss/components";
@import "tailwindcss/utilities";
@font-face {
  font-family: "Neue Machina";
  src: url("./assets/fonts/NeueMachina-Regular.ttf") format("truetype");
  font-weight: 500;
  font-style: normal;
}

@font-face {
  font-family: "Neue Machina";
  src: url("./assets/fonts/NeueMachina-Bold.ttf") format("truetype");
  font-weight: 600;
  font-style: normal;
}

@font-face {
  font-family: "Neue Machina";
  src: url("./assets/fonts/NeueMachina-Black.ttf") format("truetype");
  font-weight: 900; /* You might need to adjust this value based on the font file */
  font-style: normal;
}

* {
  margin: 0;
  box-sizing: border-box;
}
body {
  background-color: #eceff3;
  margin: 0;
  font-family: "Poppins";
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, "Courier New",
    monospace;
}
input[type="number"]::-webkit-inner-spin-button,
input[type="number"]::-webkit-outer-spin-button {
  -webkit-appearance: none;
  margin: 0;
}

.Toastify__toast-theme--light {
  border: 1px solid rgba(147, 151, 62, 0.6) !important;
  background: #fff !important;
  backdrop-filter: blur(25px) !important;
  border-radius: 10px !important;
}
.react-international-phone-input {
  background-color: inherit !important;
  height: 100% !important;
  width: 100% !important;
  border: none !important;
  color: #fff !important;
  font-size: 15px !important;
  &::placeholder {
    color: #fff !important;
  }
}
.react-international-phone-country-selector-button {
  height: 100% !important;
  background: inherit !important;
  border: none !important;
  border-radius: 12px 0 0 12px !important;
}
.react-international-phone-country-selector-dropdown {
  z-index: 999 !important;
}
/* .css-13cymwt-control,
.css-13cymwt-control:hover,
.css-13cymwt-control:focus {
  background-color: inherit !important;
  border: 1px solid rgba(255, 255, 255, 0.15) !important;
  backdrop-filter: blur(10px) !important;
  height: 64px !important;
  border-radius: 12px !important;
}
.css-13cymwt-control {
  background-color: inherit !important;
  border: 1px solid rgba(255, 255, 255, 0.15) !important;
  backdrop-filter: blur(10px) !important;
  height: 64px !important;
  border-radius: 12px !important;
  background-color: rgba(255, 255, 255, 0.05);
} */
.css-1p3m7a8-multiValue {
  background-color: rgb(195, 162, 227) !important;
  color: #fff;
}
&.css-wsp0cs-MultiValueGeneric {
  color: #fff !important;
  padding: 5px !important;
}
/* .ql-size-huge {
  color: #fff !important;
}
.ql-align-justify {
  color: #fff !important;
} */
.responsive-content {
  padding: 0 10px;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  color: #fff;
}
.responsive-content img {
  max-width: 100%;
  height: auto;
}
